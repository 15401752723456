.ScreenContainer0 {
  position: absolute; /* Layer each container */
  top: 0;
  left: 0;
  width: 100%; /* Make them take up the full width */
  height: 100%; /* Make them take up the full height */
  display: flex; /* Optional: If you want to use flexbox within each screen */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.CreateListingCon10 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  background: var(--app-gray-soft, #e0e0e0);
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 32px;
}

.CreateListingCon1 {
  position: absolute; /* Inherits layering behavior */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  display: flex; /* Flexbox layout */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  /* gap: 24px; Space between children */
  background: var(--app-gray-soft, #e0e0e0); /* Background color */
  overflow: hidden; /* Prevent horizontal overflow */
  /* overflow-y: scroll; Enable vertical scrolling */
  /* padding-bottom: 64px; */
  z-index: 200;
}

.CreateListingImagesCon1 {
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  background: var(--app-gray-soft);
}
.CreateListingImageUploadIcon {
  height: 24px;
}
.CreateListingImagesCon2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.CreateListingImagesCon3 {
  display: flex;
  padding: 0px 12px;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
}
.CreateListingImagesImageCon1 {
  display: flex;
  width: 86px;
  height: 102px;
  padding: 2px;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;

  border-radius: 5px;
  background: var(--app-gray-soft, #e0e0e0);
}
.CreateListingImagesImageCon2 {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 6px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 50px;
  background: #fff;
}
.CreateListingImagesCon4 {
  display: flex;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.CreateListingImagesOrganizeText {
  flex: 1 0 0;
  color: var(--app-orange, #ff7043);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}
.CreateListingImagesCon5 {
  display: flex;
  padding: 12px 12px;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  border-radius: 12px;
  background: var(--app-white, #e0e0e0);
}
.CreateListingImagesAddImagesText {
  color: var(--app-green, #3a7d44);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
}
.CreateListingImagesAddImagesTextHidden {
  display: none;
}

.CreateListingCon1B {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  background: var(--app-gray-soft, #e0e0e0);
}

.CreateListingCon2A {
  height: 100%;
  width: 100%;
  padding-bottom: 12px;
  max-width: 500px;
  overflow: hidden; /* Prevent horizontal overflow */
  overflow-y: scroll;
}
.CreateListingCon2 {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;
  box-sizing: border-box; /* Includes padding in the width */

  /* Enable vertical scrolling */
}

.CreateListingNameCon1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.CreateListingOptionsLabel {
  align-self: stretch;
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
}
.CreateListingOptionsLabelSecondary {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.24px;
}
.CreateListingNameInput {
  display: flex;
  height: 26px;
  padding: 2px 6px;
  align-items: center;
  align-self: stretch;

  border-radius: 3px;
  border: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);

  flex: 1 0 0;
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
}
.CreateListingNameCon2 {
  display: flex;
  padding: 1px 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  background: #fff;
}
.CreateListingNameUlrText {
  flex: 1 0 0;
  color: var(--app-orange, #ff7043);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.2px;
}
.CreateListingCon3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.CreateListingOptionsHeader {
  align-self: stretch;
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.4px;
}
.CreateListingCon4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.CreateListingOptionsChooseCon0 {
  display: flex;
  padding-bottom: 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-bottom: 0.5px solid var(--app-white, #ffffff);
}
.CreateListingOptionsChooseCon1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
  row-gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
}
.CreateListingOptionsChooseCon2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.CreateListingOptionsChooseCon3 {
  display: flex;
  padding-left: 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.CreateListingOptionsChooseSelect {
  display: flex;
  padding: 2px 12px;
  height: 26px;
  min-width: 200px;
  justify-content: center;
  align-items: center;
  border-radius: 6px 0px 0px 6px;
  border: none;
  /* border: 0.5px solid var(--app-gray-medium, #6d6d6d); */
  background: var(--app-white, #fff);
}
.CreateListingOptionsChooseChildCon1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 6px;
}
.CreateListingOptionsChooseChildCon2 {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}
.CreateListingOptionsInputCon1B {
  display: flex;
  padding-bottom: 6px;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
  row-gap: 12px;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
}
.CreateListingOptionsInputCon1 {
  display: flex;
  padding-bottom: 6px;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
  align-self: stretch;
  flex-wrap: wrap;
  border-bottom: 0.5px solid var(--app-white, #6d6d6d);
}
.CreateListingOptionsInputCon2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.CreateListingOptionsInputCon3 {
  display: flex;
  /* padding-left: 12px; */
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.CreateListingOptionsInputInput {
  width: 100%;
  padding: 5px;
  border: 1px solid var(--app-gray-medium);
  border-radius: 4px;
  box-sizing: border-box;

  flex: 1 0 0;
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
}
.CreateListingOptionsPicCon1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.CreateListingOptionsPicCon2 {
  display: flex;
  padding: 3px 6px;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;

  border-radius: 5px;
  background: var(--app-gray-soft, #e0e0e0);
}
.CreateListingOptionsPicCon3 {
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #fff;
}
.CreateListingOptionsPicCon3 {
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #fff;

  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.28px;
}
.CreateListingOptionsPicCon3Active {
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: none;
  border: 1px solid var(--app-charcoal-dark, #2b2b2b);
  background: #fff;

  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.28px;
}

.CreateListingDescriptionCon1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.CreateListingDescriptionTextarea {
  width: 100%; /* Full width */
  min-height: 200px;
  box-sizing: border-box;
  padding: 10px; /* Optional: Padding for better usability */
  border: none;
  resize: vertical; /* Allow vertical resizing only */
  border-radius: 6px;
  border: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);

  flex: 1 0 0;
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}
.CreateListingCon5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  /* padding-bottom: 64px; */
  align-self: stretch;
  padding-bottom: 32px;
}

.CreateListingErrorMessage {
  color: var(--Item-backg-highlight, #ff3c00);
  font-family: Inter;
  font-size: 12px;
  padding-left: 12px;
  padding-right: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.CreateListingImportantMessage {
  color: var(--app-green);
  font-family: Inter;
  font-size: 10px;
  padding-left: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}
/* .placeholder {
  background-color: rgba(0, 0, 0, 0.1);
  border: 5px dashed #ccc;
  width: 36px;
  height: 102px;
  height: 100px;
}

.remove-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
}

.uploaded-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
} */
/* .drop-target {
  outline: 2px dashed #0085ff; 
  background-color: rgba(0, 133, 255, 0.1);
  border-radius: 8px; 
} */

/* General styling for image container */
.CreateListingImagesImageCon1 {
  position: relative;
  /* width: 120px;  */
  /* height: 120px;  */
  margin: 8px; /* Space between images */
  display: inline-block;
  cursor: grab;
  transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transitions */
}

/* Highlight drop target */
.drop-target {
  outline: 2px dashed #0085ff;
  background-color: rgba(0, 133, 255, 0.1);
  border-radius: 8px;
  transition: outline 0.3s ease, background-color 0.3s ease; /* Smooth highlight change */
}

/* When dragging */
.CreateListingImagesImageCon1:active {
  cursor: grabbing;
  transform: scale(0.95); /* Slightly shrink for a drag effect */
  opacity: 0.7; /* Make it semi-transparent while dragging */
  z-index: 10; /* Ensure it appears above other elements */
}

/* Smooth transitions for drop zones */
.CreateListingImagesImageCon1:hover {
  transform: scale(1.05); /* Slightly grow on hover */
  transition: transform 0.2s ease; /* Faster for hover */
}

/*  */

/*  */

.CreateListingCon5B {
  width: 100%;
  /* height: 54px; */
  padding: 12px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #3a7d44 5%, rgba(58, 125, 68, 0.1) 100%);
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.CreateListingCon5ButtonCancel {
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
  background: linear-gradient(270deg, #3a7d44 0%, rgba(58, 125, 68, 0) 100%);
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;

  color: white;
  font-size: 20px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.CreateListingCon5ButtonCreateListing {
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
  background: linear-gradient(270deg, white 0%, #f4c542 100%);
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;

  color: #2b2b2b;
  font-size: 20px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

/* Select publish profile */
.SelectPublishProfileCon1 {
  position: inherit;
  height: 100%;
  width: 100%;
  display: flex;
  padding: 64px 18px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  background: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}
.SelectPublishProfileCon2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.SelectPublishProfileCon3 {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}
.SelectPublishProfileText1 {
  align-self: stretch;
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
}
.SelectPublishProfileCon4 {
  display: flex;
  padding: 0px 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.SelectPublishProfileSelectCon1 {
  display: flex;
  padding: 6px 6px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--app-white, #fff);
}
.SelectPublishProfileSelectCon2 {
  display: flex;
  width: 93px;
  height: 110px;
  padding: 6px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 0.5px solid var(--app-charcoal-dark, #2b2b2b);
  background: var(--app-gray-soft, #e0e0e0);
}
.SelectPublishProfileSelectCon3 {
  display: flex;
  width: 64px;
  height: 64px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50px;
  background: var(--app-white, #fff);
}
.SelectPublishProfileText3 {
  align-self: stretch;
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
}
.SelectPublishProfileText4 {
  align-self: stretch;
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
}
.SelectPublishProfileSelectActiveCon1 {
  display: flex;
  padding: 6px 6px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
}
.SelectPublishProfileSelectActiveCon2 {
  display: flex;
  width: 93px;
  height: 110px;
  padding: 6px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 0.5px solid var(--app-charcoal-dark, #2b2b2b);
  background: var(--app-white, #fff);
}
.SelectPublishProfileSelectActiveCon3 {
  display: flex;
  height: 64px;
  width: 64px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50px;
  background: var(--app-gray-soft, #e0e0e0);
}
.SelectPublishProfileCon5 {
  display: flex;
  padding-top: 1px;
  align-items: flex-start;
  gap: 1px;
  align-self: stretch;
  background: var(--app-charcoal-dark, #2b2b2b);
}
.SelectPublishProfileCon6 {
  display: flex;
  padding: 12px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--app-gray-soft, #e0e0e0);
}
.SelectPublishProfileCon6B {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  background: var(--app-white, #fff);
}
.SelectPublishProfileText2 {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
}
.SelectPublishProfileCon7 {
  display: flex;
  padding: 12px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--app-gold-yellow, #f4c542);
}

.CreateInputAdjustDiscountOnPriceCon1 {
  display: flex;
  padding: 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.CreateInputAdjustDiscountOnPriceCon2 {
  display: flex;
  padding: 6px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  border-radius: 12px;
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
