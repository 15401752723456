.ScreenContainer0 {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.CreateListingCon10 {
  background: var(--app-gray-soft, #e0e0e0);
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  height: 100%;
  padding-bottom: 32px;
  display: flex;
  overflow: hidden scroll;
}

.CreateListingCon1 {
  background: var(--app-gray-soft, #e0e0e0);
  z-index: 200;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.CreateListingImagesCon1 {
  background: var(--app-gray-soft);
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  padding: 12px 0;
  display: flex;
}

.CreateListingImageUploadIcon {
  height: 24px;
}

.CreateListingImagesCon2 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.CreateListingImagesCon3 {
  flex-wrap: wrap;
  place-content: flex-start center;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  padding: 0 12px;
  display: flex;
}

.CreateListingImagesImageCon1 {
  background: var(--app-gray-soft, #e0e0e0);
  border-radius: 5px;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  width: 86px;
  height: 102px;
  padding: 2px;
  display: flex;
}

.CreateListingImagesImageCon2 {
  background: #fff;
  border-radius: 50px;
  flex-shrink: 0;
  align-items: center;
  gap: 10px;
  width: 24px;
  height: 24px;
  padding: 6px;
  display: flex;
}

.CreateListingImagesCon4 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 0 24px;
  display: flex;
}

.CreateListingImagesOrganizeText {
  color: var(--app-orange, #ff7043);
  text-align: center;
  letter-spacing: .24px;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.CreateListingImagesCon5 {
  background: var(--app-white, #e0e0e0);
  border-radius: 12px;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  padding: 12px;
  display: flex;
}

.CreateListingImagesAddImagesText {
  color: var(--app-green, #3a7d44);
  letter-spacing: .28px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.CreateListingImagesAddImagesTextHidden {
  display: none;
}

.CreateListingCon1B {
  background: var(--app-gray-soft, #e0e0e0);
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  padding: 0 12px;
  display: flex;
}

.CreateListingCon2A {
  width: 100%;
  max-width: 500px;
  height: 100%;
  padding-bottom: 12px;
  overflow: hidden scroll;
}

.CreateListingCon2 {
  box-sizing: border-box;
  background: #fff;
  border-radius: 12px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  padding: 12px;
  display: flex;
}

.CreateListingNameCon1 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.CreateListingOptionsLabel {
  color: var(--app-gray-medium, #6d6d6d);
  letter-spacing: .28px;
  align-self: stretch;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.CreateListingOptionsLabelSecondary {
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .24px;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.CreateListingNameInput {
  border: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  height: 26px;
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .36px;
  border-radius: 3px;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
  padding: 2px 6px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.CreateListingNameCon2 {
  background: #fff;
  border-radius: 5px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 1px 2px;
  display: flex;
}

.CreateListingNameUlrText {
  color: var(--app-orange, #ff7043);
  letter-spacing: .2px;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.CreateListingCon3 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.CreateListingOptionsHeader {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  letter-spacing: .4px;
  align-self: stretch;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.CreateListingCon4 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  display: flex;
}

.CreateListingOptionsChooseCon0 {
  border-bottom: .5px solid var(--app-white, #fff);
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  padding-bottom: 6px;
  display: flex;
}

.CreateListingOptionsChooseCon1 {
  flex-wrap: wrap;
  place-content: flex-end space-between;
  align-self: stretch;
  align-items: flex-end;
  row-gap: 12px;
  display: flex;
}

.CreateListingOptionsChooseCon2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.CreateListingOptionsChooseCon3 {
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding-left: 12px;
  display: flex;
}

.CreateListingOptionsChooseSelect {
  background: var(--app-white, #fff);
  border: none;
  border-radius: 6px 0 0 6px;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  height: 26px;
  padding: 2px 12px;
  display: flex;
}

.CreateListingOptionsChooseChildCon1 {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.CreateListingOptionsChooseChildCon2 {
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  display: flex;
}

.CreateListingOptionsInputCon1B {
  flex-wrap: wrap;
  place-content: flex-end space-between;
  align-self: stretch;
  align-items: flex-end;
  gap: 12px;
  padding-bottom: 6px;
  display: flex;
}

.CreateListingOptionsInputCon1 {
  border-bottom: .5px solid var(--app-white, #6d6d6d);
  flex-wrap: wrap;
  place-content: flex-end space-between;
  align-self: stretch;
  align-items: flex-end;
  padding-bottom: 6px;
  display: flex;
}

.CreateListingOptionsInputCon2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.CreateListingOptionsInputCon3 {
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: flex;
}

.CreateListingOptionsInputInput {
  border: 1px solid var(--app-gray-medium);
  box-sizing: border-box;
  width: 100%;
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .36px;
  border-radius: 4px;
  flex: 1 0 0;
  padding: 5px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.CreateListingOptionsPicCon1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.CreateListingOptionsPicCon2 {
  background: var(--app-gray-soft, #e0e0e0);
  border-radius: 5px;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  padding: 3px 6px;
  display: flex;
}

.CreateListingOptionsPicCon3 {
  color: var(--app-gray-medium, #6d6d6d);
  letter-spacing: .28px;
  background: #fff;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 2px 4px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
}

.CreateListingOptionsPicCon3Active {
  border: none;
  border: 1px solid var(--app-charcoal-dark, #2b2b2b);
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .28px;
  background: #fff;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px 6px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
}

.CreateListingDescriptionCon1 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.CreateListingDescriptionTextarea {
  box-sizing: border-box;
  resize: vertical;
  border: none;
  border: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  width: 100%;
  min-height: 200px;
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .32px;
  border-radius: 6px;
  flex: 1 0 0;
  padding: 10px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.CreateListingCon5 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  padding-bottom: 32px;
  display: flex;
}

.CreateListingErrorMessage {
  color: var(--Item-backg-highlight, #ff3c00);
  padding-left: 12px;
  padding-right: 12px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.CreateListingImportantMessage {
  color: var(--app-green);
  letter-spacing: .32px;
  padding-left: 12px;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.CreateListingImagesImageCon1 {
  cursor: grab;
  margin: 8px;
  transition: transform .3s, opacity .3s;
  display: inline-block;
  position: relative;
}

.drop-target {
  background-color: #0085ff1a;
  border-radius: 8px;
  outline: 2px dashed #0085ff;
  transition: outline .3s, background-color .3s;
}

.CreateListingImagesImageCon1:active {
  cursor: grabbing;
  opacity: .7;
  z-index: 10;
  transform: scale(.95);
}

.CreateListingImagesImageCon1:hover {
  transition: transform .2s;
  transform: scale(1.05);
}

.CreateListingCon5B {
  box-sizing: border-box;
  background: linear-gradient(#3a7d44 5%, #3a7d441a 100%);
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 12px;
  display: flex;
}

.CreateListingCon5ButtonCancel {
  color: #fff;
  word-wrap: break-word;
  background: linear-gradient(270deg, #3a7d44 0%, #3a7d4400 100%);
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 3px 6px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  display: flex;
}

.CreateListingCon5ButtonCreateListing {
  color: #2b2b2b;
  word-wrap: break-word;
  background: linear-gradient(270deg, #fff 0%, #f4c542 100%);
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 3px 6px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  display: flex;
}

.SelectPublishProfileCon1 {
  position: inherit;
  box-sizing: border-box;
  background: #0003;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  padding: 64px 18px;
  display: flex;
}

.SelectPublishProfileCon2 {
  background: #fff;
  border-radius: 12px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
  box-shadow: 0 4px 4px 2px #00000040;
}

.SelectPublishProfileCon3 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  padding: 12px;
  display: flex;
}

.SelectPublishProfileText1 {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  letter-spacing: .32px;
  align-self: stretch;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.SelectPublishProfileCon4 {
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  padding: 0 24px;
  display: flex;
}

.SelectPublishProfileSelectCon1 {
  background: var(--app-white, #fff);
  border-radius: 6px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 6px;
  display: flex;
}

.SelectPublishProfileSelectCon2 {
  border: .5px solid var(--app-charcoal-dark, #2b2b2b);
  background: var(--app-gray-soft, #e0e0e0);
  border-radius: 12px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 93px;
  height: 110px;
  padding: 6px;
  display: flex;
}

.SelectPublishProfileSelectCon3 {
  background: var(--app-white, #fff);
  border-radius: 50px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  display: flex;
}

.SelectPublishProfileText3 {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  letter-spacing: .24px;
  align-self: stretch;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.SelectPublishProfileText4 {
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .16px;
  align-self: stretch;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.SelectPublishProfileSelectActiveCon1 {
  background: var(--app-white, #fff);
  border-radius: 6px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 6px;
  display: flex;
  box-shadow: 0 4px 12px #00000040;
}

.SelectPublishProfileSelectActiveCon2 {
  border: .5px solid var(--app-charcoal-dark, #2b2b2b);
  background: var(--app-white, #fff);
  border-radius: 12px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 93px;
  height: 110px;
  padding: 6px;
  display: flex;
}

.SelectPublishProfileSelectActiveCon3 {
  background: var(--app-gray-soft, #e0e0e0);
  border-radius: 50px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  display: flex;
}

.SelectPublishProfileCon5 {
  background: var(--app-charcoal-dark, #2b2b2b);
  align-self: stretch;
  align-items: flex-start;
  gap: 1px;
  padding-top: 1px;
  display: flex;
}

.SelectPublishProfileCon6 {
  background: var(--app-gray-soft, #e0e0e0);
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 12px 0;
  display: flex;
}

.SelectPublishProfileCon6B {
  background: var(--app-white, #fff);
  flex-direction: column;
  flex: 1 0 0;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.SelectPublishProfileText2 {
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .32px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.SelectPublishProfileCon7 {
  background: var(--app-gold-yellow, #f4c542);
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 12px 0;
  display: flex;
}

.CreateInputAdjustDiscountOnPriceCon1 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  padding: 6px;
  display: flex;
}

.CreateInputAdjustDiscountOnPriceCon2 {
  background: var(--app-white, #fff);
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  padding: 6px 12px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}
/*# sourceMappingURL=index.77f1e96c.css.map */
